.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.app .pane-contents {
  display: flex;
}
.app .contents-wrapper {
  flex: 1 1 auto;
  align-items: stretch;
  display: flex;
  overflow-y: hidden;
  position: relative;
}
.app .facility-config .page-content {
  line-height: 1.15;
}
