.initializing-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.initializing-wrapper p.initializing-status {
  text-align: center;
}
.initializing-wrapper p.initializing-status strong {
  font-size: 2em;
}
