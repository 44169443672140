.license-note {
  max-width: 18.75rem !important;
  font-size: 0.75rem;
  margin: 0.25rem 0 0;
}
.license-ddl-popup {
  min-width: 14.0625rem !important;
}
.license-ddl-popup .item-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem 0.125rem;
  border-bottom: 0.0625rem;
}
.license-ddl-popup .item-wrap.available .seats {
  color: #349946;
}
.license-ddl-popup .item-wrap.unavailable .seats {
  color: #ef470a;
}
.license-ddl-popup .k-list .k-item:hover {
  background-color: #d7d7d7;
}
.license-ddl-popup .k-list .k-item.k-state-selected {
  background-color: #f0f0f0;
  color: #000;
}
.license-ddl-popup .k-list .k-item:hover.k-state-selected {
  background-color: #d7d7d7;
  color: #000;
}
.license-ddl-popup .package-name {
  flex: 1 1 auto;
}
.license-ddl-popup .seats {
  flex: 1 1 14.0625rem;
  font-size: 0.75rem;
}
.license-ddl-popup .current {
  font-size: 0.75rem;
  color: #000;
  flex: 0 0 auto;
}
