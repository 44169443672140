#error .page-content {
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
}
#error .page-content p {
  margin: 0.375rem auto;
  text-align: center;
}
#error .page-content details {
  font-size: 0.625rem;
  width: 15rem;
  max-height: 6.25rem;
  overflow: auto;
  margin: 1.5625rem auto;
  border: 0.0625rem solid #eee;
}
#error .page-content details > summary {
  padding: 0.625rem;
  cursor: pointer;
}
#error .page-content details > summary:hover {
  background-color: #eee;
}
