.create-new-encounter-drawer .row-wrapped {
  width: 100%;
  margin-bottom: 0.625rem;
}
.create-new-encounter-drawer .k-button.tc-button.icon-only {
  padding: 4px 8px;
  font-size: 14px;
  width: 100px;
}
.create-new-encounter-drawer .k-textbox-container {
  width: 100%;
}
.create-new-encounter-drawer .k-textbox-container.k-state-empty label {
  opacity: 0.4;
}
.create-new-encounter-drawer .k-textbox-container.k-state-empty.k-state-focused label {
  opacity: 1;
}
.create-new-encounter-drawer .create-encounter-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  cursor: default;
  text-decoration: none;
}
.create-new-encounter-drawer .create-encounter-button:focus-visible {
  outline: none;
}
.create-new-encounter-drawer .create-encounter-button:focus-visible button {
  outline: 2px solid black;
  outline-offset: -2px;
}
.create-new-encounter-drawer .create-encounter-button:after {
  content: none;
}
.create-new-encounter-drawer i {
  font-size: 0.75rem;
  color: #cccccc;
  padding-bottom: 0;
  font-weight: 100;
}
