.notice-block {
  padding: 0.75rem 0;
  border-bottom: 1px solid #cdcdcd;
}
.notice-block.last {
  border-bottom: 0 none;
}
.notice-caption,
.notice-time {
  display: block;
}
.notice-time {
  color: #474841;
}
