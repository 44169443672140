.error-context {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
}
.error-text {
  font-size: 1.5rem;
}
.error-description {
  padding-top: 0.5rem;
  font-size: 1.2rem;
}
