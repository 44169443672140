.tc-dialog.k-dialog-wrapper {
  z-index: 10091;
}
.tc-dialog .k-dialog {
  max-height: 90vh;
  width: 90vw;
  max-width: 37.5rem;
}
.tc-dialog .organization-name {
  font-weight: bold;
}
