.tc-appbar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  border-color: #363940;
  text-align: left;
}
.tc-appbar-menu:focus {
  outline: none;
}
.tc-appbar-menu li {
  margin: 0;
}
.tc-appbar-menu li.info {
  cursor: default;
}
.tc-appbar-menu li.info,
.tc-appbar-menu li .tc-button {
  padding: 0.625rem 1.25rem;
}
.tc-appbar-menu li .has-icon.tc-button {
  padding: 0.625rem 0.5rem 0.625rem 0.625rem;
}
.tc-appbar-menu li .has-icon.tc-button i.icon {
  margin-right: 0.875rem;
}
.tc-appbar-menu li .tc-button,
.tc-appbar-menu li a {
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: inline-block;
}
.tc-appbar-menu li.separator {
  border-top: 0.0625rem solid #ddd;
}
.tc-appbar-menu a {
  color: #4c5356;
  text-decoration: none;
}
.tc-appbar-menu a.help,
.tc-appbar-menu a[target="_blank"] {
  width: auto;
  display: flex;
  flex-direction: column;
}
.tc-appbar-menu a.help:hover,
.tc-appbar-menu a[target="_blank"]:hover {
  text-decoration: none;
}
.tc-appbar-menu a.help:after,
.tc-appbar-menu a[target="_blank"]:after {
  content: none;
  padding-left: 0;
  font-size: inherit;
}
.tc-appbar-menu a.help .external-button,
.tc-appbar-menu a[target="_blank"] .external-button {
  display: flex;
}
.tc-appbar-menu .fa-external-link {
  font-size: 0.625rem;
  color: #b3b9bb;
  padding: 1rem 0.5rem 0.625rem 0.5rem;
}
.tc-appbar-menu.right {
  text-align: right;
}
.tc-appbar-menu.right button {
  text-align: right;
}
.tc-appbar-menu.center {
  text-align: center;
}
.tc-appbar-menu.center button {
  text-align: center;
}
.tc-appbar-menu .revision {
  opacity: 0.4;
  font-size: 0.6875rem;
  padding: 0.3125rem;
}
.tc-appbar-menu .revision .version {
  font-family: monospace;
}
.tc-appbar-menu .client-mode {
  display: none;
  opacity: 0.4;
  font-size: 0.6875rem;
  padding: 0.3125rem;
}
.tc-appbar-menu .client-mode.show-true {
  display: list-item;
}
.tc-appbar-menu .user-external-button .tc-button {
  text-align: right;
}
.tc-appbar-menu .user-external-button .fa-external-link {
  padding: 0;
}
