.tc-floating-button {
  z-index: 10000;
  opacity: 0.75;
  bottom: 2rem !important;
  right: 1.375rem !important;
}
.tc-floating-button:hover {
  opacity: 1;
}
.tc-floating-button span {
  margin-top: 0.125rem;
}
.tc-floating-button span.k-fab-text {
  margin-top: 0;
  margin-left: 0.3125rem;
}
