.tc-appbar.k-appbar {
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 0.25rem 0.5rem;
  background-color: #6F8FAF;
  color: #ffffff;
}
.tc-appbar.k-appbar .k-avatar-solid.k-avatar-primary {
  background-color: #456081;
}
.tc-appbar.k-appbar .k-appbar-section .title {
  font-size: 1.25rem;
  margin: 0;
  font-family: serif;
  font-weight: normal;
  display: inline-block;
}
.tc-appbar.k-appbar .k-appbar-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.tc-appbar.k-appbar .k-appbar-section li {
  margin: 0 0.1875rem;
}
.tc-appbar.k-appbar .k-appbar-section .k-button.tc-button {
  padding: 0.25rem 0.5rem;
}
.tc-appbar.k-appbar .k-appbar-section .k-badge-container .k-badge-solid.k-badge-primary {
  background-color: #456081;
  border-color: ligthen(#456081, 10%);
  color: #fff;
}
.tc-appbar.k-appbar .k-appbar-section a,
.tc-appbar.k-appbar .k-appbar-section button {
  color: #ffffff;
  text-decoration: none;
}
.tc-appbar.k-appbar .k-appbar-section a[target="_self"] .tc-button {
  padding-right: 0;
}
.tc-appbar.k-appbar .k-appbar-section a[target="_blank"] .tc-button {
  padding-right: 0;
}
.tc-appbar.k-appbar .k-appbar-section a[target="_blank"]:after {
  font-size: 0.625rem;
  font-family: "Font Awesome 5 Pro";
  color: #cccccc;
  content: '\f08e';
  padding-bottom: 0;
  font-weight: 100;
}
.tc-appbar.k-appbar .k-appbar-section a[target="_blank"].recovery-link:after {
  content: none;
}
.tc-appbar.k-appbar .organization-name {
  color: #ffffff;
  padding: 5px 7px;
  border-top: 1px solid;
  border-bottom: 1px solid;
}
