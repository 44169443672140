.tc-datepicker {
  display: flex;
}
.tc-datepicker.k-input {
  padding: 0;
}
.tc-datepicker .k-datepicker {
  width: auto;
}
.tc-datepicker .fullDiv,
.tc-datepicker .fullDiv input {
  width: 100%;
}
.tc-datepicker .inputDiv,
.tc-datepicker .k-datepicker {
  height: 1.875rem;
}
.tc-datepicker .inputDiv {
  flex: 1 1 auto;
}
.tc-datepicker .inputDiv input {
  width: 100%;
}
.tc-datepicker .dateDiv {
  flex: 0 0 auto;
}
.tc-datepicker .custom_date_input {
  display: none;
}
