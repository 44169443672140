.snapshot-hint {
  border-bottom: 0.0625rem solid;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
}
dl.inline-flex {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  /* set the container width*/
  overflow: visible;
}
dl.inline-flex dt {
  flex: 0 0 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: help;
}
dl.inline-flex dd {
  flex: 0 0 calc(100% - 250px);
  margin-left: auto;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}
dl.inline-flex dd textarea {
  width: 90%;
}
.network-activities {
  padding-top: 50px;
}
.network-activities-grid {
  height: 400px;
  width: 520px;
  overflow-y: hidden;
  word-wrap: break-word;
}
