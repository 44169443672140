.invalid-value {
  border: 1px solid #e20000;
}
.ddl-no-overflow {
  overflow: hidden;
  white-space: nowrap;
}
.highlight-filter {
  color: #2fa3ff;
}
