.tc-combobox {
  height: 100% !important;
  padding: 0 !important;
}
.combobox-class-revenueCode.sort-workaround {
  z-index: 10000;
}
.sort-workaround.k-dropdowngrid-popup .k-grid-header .k-header {
  padding: 0;
}
.sort-workaround .mccb-header {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}
