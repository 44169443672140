.cipher .editor-wrap {
  position: relative;
  overflow: auto;
}
.cipher .editor-overlay {
  top: 0;
  position: absolute;
}
.cipher .editor {
  top: 0;
  position: absolute;
}
.cipher .editor ul,
.cipher .editor ol {
  margin: 0 3px;
}
.cipher .k-i-custom-link:before {
  content: "\e10f";
}
