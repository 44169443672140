.tc-grid.tc-grid-disabled {
  background-color: #cab9b0;
  cursor: not-allowed;
}
.tc-grid.tc-grid-disabled .k-switch {
  cursor: not-allowed;
}
.tc-grid.tc-grid-disabled .k-grid-content {
  opacity: 0.8;
}
.tc-grid tbody tr.k-master-row:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
