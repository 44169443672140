.page-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
}
.page-content.column {
  flex-direction: column;
}
.page-content.scrollable {
  overflow-y: hidden;
}
.page-content > div {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.page-content .no-scroll {
  flex: 0 0 auto !important;
  overflow: hidden !important;
}
.page-content > .k-splitter,
.page-content > .k-grid {
  border-top: 0 none;
}
