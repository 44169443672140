// these match up to the cipher.theme.less variables...
// FIXME: find a better way to do this

// $accent
/// @deprecated Will be removed in v5. Use `$primary` variable instead.
$accent: #6790b0;
$primary: #6790b0;

$border-radius: 2px;

$input-border: rgba(black, 0.2);

$custom-input-border: #99ffa0;

$input-hovered-border: rgba($custom-input-border, 1);
$input-focused-border: rgba($custom-input-border, 1);
$input-focused-shadow: 0 0 0 2px rgba($input-focused-border, 0.6);

$combobox-hovered-border: $input-hovered-border;
$combobox-focused-border: $input-focused-border;
$combobox-focused-shadow: $input-focused-shadow;

$dropdownlist-hovered-border: $input-hovered-border;
$dropdownlist-focused-border: $input-focused-border;
$dropdownlist-focused-shadow: $input-focused-shadow;

$checkbox-border: rgba(black, 0.2);

$grid-hovered-bg: inherit;

@import '~@progress/kendo-theme-default/dist/all.scss';

.cipher .k-grid tbody tr.k-alt {
  &.k-state-hover,
  &:hover {
    background-color: $grid-alt-bg;
  }
}

.k-window.trucode .k-overlay {
  display: none;
}

.k-calendar-centuryview .k-link,
.k-calendar-decadeview .k-link,
.k-calendar-yearview .k-link {
  width: 44.8px;
  height: 44.8px;
}
