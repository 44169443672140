.tc-popup.k-animation-container-shown {
  box-shadow: none;
  padding: 0 !important;
}
.tc-popup.edits-popup.k-animation-container {
  z-index: 10092;
}
.tc-popup-popup.popup-recovery {
  padding: 5px;
  border: 2px solid #ccc;
}
.tc-popup-popup.popup-recovery .warning-icon {
  float: left;
  font-size: 18px;
  padding: 10px 8px;
  color: #f9cc22;
}
.tc-popup-popup.popup-recovery .recovery-link {
  padding-left: 5px;
}
.tc-popup-popup.popup-recovery .k-button {
  float: right;
  font-size: 16px;
}
