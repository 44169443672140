.empty-body {
  flex: 1 1 auto;
  align-self: center;
}
.empty-body p {
  text-align: center;
  margin: 0.5rem 0;
}
.empty-body .icon {
  font-size: 3rem;
}
.empty-body .msg {
  font-size: 1.375rem;
}
.empty-body .body-with-scroll {
  max-height: 400px;
  overflow-y: auto;
}
