.announcement-list {
  margin: 0;
  padding: 0;
  max-width: 25rem;
}
.announcement-list li {
  padding: 0.25rem;
  display: flex;
  border-bottom: 0.0625rem solid #dae2eb;
}
.announcement-list li:last-child {
  border-bottom: 0 none;
}
.announcement-list li .message {
  flex: 1 1 auto;
  padding: 0.25rem;
}
.announcement-list li .action {
  flex: 0 0 auto;
  padding: 0;
  min-width: 0;
}
