.tc-skeleton-wrap {
  display: flex;
  flex: 1 1 auto;
}
.tc-skeleton-wrap.tc-skeleton-row {
  flex-direction: column;
}
.tc-skeleton-wrap .tc-skeleton {
  flex: 1 1 auto;
  display: inline-flex;
  margin-left: 0.0625rem;
  margin-right: 0.0625rem;
}
.tc-skeleton-wrap .tc-skeleton.fixed {
  flex: 0 0 auto !important;
}
.tc-skeleton.tc-skeleton-button {
  width: 6.25rem;
  height: 1.875rem;
  display: inline-flex;
}
.tc-skeleton.pad-top {
  margin-top: 4rem !important;
}
.tc-skeleton.pad-bottom {
  margin-bottom: 4rem !important;
}
.tc-skeleton.inline {
  display: inline-flex !important;
}
.tc-skeleton.invert {
  background-color: rgba(255, 255, 255, 0.4);
}
.tc-skeleton.tc-skeleton-input {
  min-width: 1.875rem;
  height: 1.875rem;
  display: inline-flex;
  margin-right: 0.25rem;
}
.tc-skeleton.tc-skeleton-input.small {
  width: 3.75rem;
}
.tc-skeleton.tc-skeleton-input.medium {
  width: 12.5rem;
}
.tc-skeleton.tc-skeleton-input.large {
  width: 31.25rem;
}
.tc-skeleton.tc-skeleton-grid-row {
  min-width: 1.875rem;
  height: 1.875rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: block;
}
.tc-skeleton.tc-skeleton-header,
.tc-skeleton.tc-skeleton-grid-header {
  width: 11.25rem;
  height: 1.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  display: block;
}
