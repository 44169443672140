.sidebar-nav-wrapper {
  background-color: #456081;
  color: #fff;
  border-right: 0.0625rem solid #999;
  display: flex;
  overflow-y: hidden;
  flex: 0 0 auto !important;
}
.sidebar-nav-wrapper.fixed-width {
  width: 15.625rem;
}
.sidebar-nav-wrapper .sidebar-nav {
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0.125rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  flex: 1 1 auto;
}
.sidebar-nav-wrapper .sidebar-nav h2,
.sidebar-nav-wrapper .sidebar-nav .custom-heading {
  margin-top: 0;
  margin-left: 1rem;
  margin-bottom: 0;
  margin-right: 0.75rem;
  margin-top: 0.625rem;
  flex: 0 0 auto;
  border-bottom: 0.0625rem solid #7f9cb8;
  padding-bottom: 0.625rem;
}
.sidebar-nav-wrapper .sidebar-nav h2 {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.25rem;
}
.sidebar-nav-wrapper .sidebar-nav .custom-heading .k-button {
  padding: 0.3125rem 0.625rem;
  width: 100%;
}
.sidebar-nav-wrapper .sidebar-nav ul {
  flex: 1 1 auto;
  padding: 0 0.25rem;
  margin: 0;
  list-style: none;
  overflow-y: auto;
  text-overflow: ellipsis;
}
.sidebar-nav-wrapper .sidebar-nav ul li {
  margin: 0.375rem 0;
}
.sidebar-nav-wrapper .sidebar-nav ul a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.125rem;
  padding: 0.25rem 0.75rem 0.25rem 1.5rem;
  display: block;
  clear: both;
  border-radius: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-nav-wrapper .sidebar-nav ul a.active {
  background-color: #6F8FAF;
}
.sidebar-nav-wrapper .sidebar-nav ul a:hover {
  background-color: #547597;
}
.sidebar-nav-wrapper .sidebar-nav ul .icon {
  width: 2rem;
}
.sidebar-nav-wrapper .sidebar-nav ul span {
  display: inline-block;
  padding-left: 0.25rem;
}
