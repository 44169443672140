.code-selector-wrapper {
  display: inline-flex !important;
  margin-left: 0.5rem;
}
.code-selector-wrapper > .k-button {
  flex: 0 0 auto;
}
.code-selector-wrapper > .k-button .icon {
  visibility: visible;
}
.code-selector-wrapper > div.tc-error-wrap {
  flex: 1 1 auto;
}
.code-selector-wrapper > div.tc-error-wrap input {
  width: 5rem;
  text-overflow: clip !important;
  padding: 0.25rem 0.375rem;
}
.grid-type-outProcedures .code-selector-wrapper > div.tc-error-wrap input {
  width: 3.5rem;
}
.grid-type-inProcedures .code-selector-wrapper > div.tc-error-wrap input {
  width: 4.5rem;
}
.read-only-code {
  background-color: #dcdcdc !important;
}
