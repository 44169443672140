.k-button.tc-button {
  align-items: center;
  background-image: none !important;
  min-width: 6.25rem;
}
.k-button.tc-button.tc-danger {
  border-color: #ae2321;
  background-color: #db4240;
  color: #fff;
  background-image: none !important;
}
.k-button.tc-button.tc-danger:hover,
.k-button.tc-button.tc-danger.k-state-hover {
  background-color: #c32725;
}
.k-button.tc-button.tc-danger:active,
.k-button.tc-button.tc-danger.k-state-active {
  background-color: #981e1d;
}
.k-button.tc-button.tc-danger:focus,
.k-button.tc-button.tc-danger.k-state-focus,
.k-button.tc-button.tc-danger.k-state-focused {
  box-shadow: 0 0 0 2px rgba(219, 66, 64, 0.08);
}
.k-button.tc-button.tc-success {
  border-color: #21602c;
  background-color: #349946;
  color: #fff;
  background-image: none !important;
}
.k-button.tc-button.tc-success:hover,
.k-button.tc-button.tc-success.k-state-hover {
  background-color: #277335;
}
.k-button.tc-button.tc-success:active,
.k-button.tc-button.tc-success.k-state-active {
  background-color: #1a4d23;
}
.k-button.tc-button.tc-success:focus,
.k-button.tc-button.tc-success.k-state-focus,
.k-button.tc-button.tc-success.k-state-focused {
  box-shadow: 0 0 0 2px rgba(52, 153, 70, 0.08);
}
.k-button.tc-button.tc-btn-transparent {
  background-color: transparent;
  border: 0 none;
}
.k-button.tc-button .icon {
  margin-right: 0.375rem;
}
.k-button.tc-button .icon.right {
  margin-right: 0;
  margin-left: 0.25rem;
}
.k-button.tc-button .icon.tc-menu {
  font-size: 70%;
  vertical-align: middle;
  margin-left: 0.25rem;
  margin-right: 0;
}
.k-button.tc-button.icon-only {
  padding: 0;
  font-size: 1.25rem;
  min-width: auto;
}
.k-button.tc-button.icon-only .icon {
  margin-right: 0;
}
