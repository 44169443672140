.tc-drawer .k-drawer {
  background-color: #ffffff;
}
.tc-drawer .drawer-wrap {
  position: relative;
  padding: 0.375rem 0.625rem 0.625rem;
}
.tc-drawer .drawer-wrap h1 {
  margin-top: 0;
  font-size: 1.5rem;
}
.tc-drawer .drawer-wrap .drawer-close {
  position: absolute;
  top: 0.375rem;
  right: 0.25rem;
  font-size: 1.375rem;
}
.tc-drawer .drawer-wrap .drawer-button-hidden {
  position: absolute;
  top: 0rem;
  left: -10rem;
}
